import React, { useRef } from 'react'
// CSS
import '../Content.css'
// SLIDER
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// IMGS
import WhatWeDo from '../../../assets/img/WhatWeDo.png'
import HowDidWeDoIt from '../../../assets/img/HowDidWeDoIt.png'
import ValueOfTechnologies from '../../../assets/img/ValueOfTechnologies.png'
import GlooudsTools from '../../../assets/img/GlooudsTools.png'
import OpportunityMap from '../../../assets/img/OpportunityMap.png'
import Fan20 from '../../../assets/img/Fan20.png'
import DigitalAssets from '../../../assets/img/DigitalAssets.png'
import GlooudsMetaverse from '../../../assets/img/GlooudsMetaverse.png'
import FIFAGloouds from '../../../assets/img/FIFAGloouds.png'
import GlooudsTeamBuilder from '../../../assets/img/GlooudsTeamBuilder.png'
import GlooudsUtilityToken from '../../../assets/img/GlooudsUtilityToken.png'
import GlooudsPartners from '../../../assets/img/GlooudsPartners.jpeg'

import glooudsSLlide01 from "../../../assets/Slider/glooudsSlide01.png";
import glooudsSLlide02 from "../../../assets/Slider/glooudsSlide02.png";
import glooudsSLlide03 from "../../../assets/Slider/glooudsSlide03.png";
import glooudsSLlide04 from "../../../assets/Slider/glooudsSlide04.png";
import glooudsSLlide05 from "../../../assets/Slider/glooudsSlide05.png";
import glooudsSLlide06 from "../../../assets/Slider/glooudsSlide06.png";
import glooudsSLlide07 from "../../../assets/Slider/glooudsSlide07.png";
import glooudsSLlide08 from "../../../assets/Slider/glooudsSlide08.png";
import glooudsSLlide09 from "../../../assets/Slider/glooudsSlide09.png";
import glooudsSLlide10 from "../../../assets/Slider/glooudsSlide10.png";
import glooudsSLlide11 from "../../../assets/Slider/glooudsSlide11.png";
import glooudsSLlide12 from "../../../assets/Slider/glooudsSlide12.png";


const Carousel = () => {

    const buttonNames = [
        { name: "PERFORMANCE", id: 1 },
        { name: "OPORTUNITY MAP", id: 2 },
        { name: "FAN 2.0", id: 3 },
        { name: "TEAM BUILDER", id: 4 },
        { name: "MISION & VISION", id: 5 }
    ]

    /* const carouselImages = [
        { img: WhatWeDo },
        { img: HowDidWeDoIt },
        { img: ValueOfTechnologies },
        { img: GlooudsTools, id: 1 },
        { img: OpportunityMap, id: 2 },
        { img: Fan20, id: 3 },
        { img: DigitalAssets, id: 4 },
        { img: GlooudsMetaverse, id: 5 },
        { img: FIFAGloouds },
        { img: GlooudsTeamBuilder },
        { img: GlooudsUtilityToken },
        { img: GlooudsPartners},
    ]; */

    const carouselImages = [
        { img: glooudsSLlide01 },
        { img: glooudsSLlide02, id: 1 },
        { img: glooudsSLlide03, id: 2},
        { img: glooudsSLlide04, id: 3 },
        { img: glooudsSLlide05 },
        { img: glooudsSLlide06, id: 4 },
        { img: glooudsSLlide07 },
        { img: glooudsSLlide08 },
        { img: glooudsSLlide09 },
        { img: glooudsSLlide10 },
        { img: glooudsSLlide11 , id: 5},
        { img: glooudsSLlide12 }
    ]

    const sliderRef = React.useRef();

    const goToSlide = (id) => {
        
        const imageIndex = carouselImages.findIndex((image) => image.id === id);

        if(sliderRef.current){
            sliderRef.current.slickGoTo(imageIndex)
        }
    }

    const settings = {
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className="carouselGeneralContainer">
            <div className="buttonsContainer">
                {buttonNames?.map((data, index) => {
                    return(
                        <button key={`button-${index}`} className='buttons' onClick={() => goToSlide(data.id)}> {data.name} </button>
                    )
                    })}
            </div>
            <Slider ref={sliderRef} {...settings} className='carousel'>
                {carouselImages?.map((data, index) => {
                    return(
                        <div className='imagesContainer' key={`img-${index}`}>
                            <img src={data.img} alt={`Imagen ${index + 1}`} className='images'/>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default Carousel