import cafu from "../../assets/Cafu.png";
import kempes from "../../assets/Kempes.png";
import "./Ambassadors.css"


const Ambassadors = () => {
  
    return (
      <>
        <div className='ambassadorsTextAndImgContainer'>
            <img src={cafu} alt="" className='ambassadorsImg'/>
            <p className='ambassadorsText'>
                La inclusión de <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}>Cafú</b> y 
                <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> Kempes</b> como  
                <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> Embajadores de GLOOUDS </b>
                tiene un impacto significativo tanto en la marca como en la campaña de la compañía, así como en la promoción de los valores que 
                <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> GLOOUDS</b> fomenta a través del fútbol juvenil local. 
                Con sus trayectorias destacadas y reconocimiento a nivel mundial, 
                <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> Cafú</b> y 
                <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> Kempes </b> 
                encarnan valores positivos y liderazgo, al mismo tiempo que refuerzan la imagen y la credibilidad de la compañía.
            </p>
            <img src={kempes} alt="" className='ambassadorsImg'/>
        </div>
    </>
    );
};
export default Ambassadors;