import "./HowWeDoIt.css"
import HowWeDoItImage from "../../assets/HowWeDoIt.png"


const HowWeDoIt = () => {
  
    return (
      <>
        <div className='hwdiTextAndImgContainer'>
            <div className="hwdiTextBox">
                <p className='hwdiText'>MONETIZACIÓN EN DOS PASOS</p>
                <p className="hwdiText" style={{color:"#00ffff", fontFamily:"var(--font700)", borderBottom:'solid #00ffff 3px'}}>¿CÓMO LO HACEMOS?</p>
                <p className="ambassadorsText">
                    <b className="hwdiNumber">1.</b>
                    <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> GLOOUDS – Conclusiones (ROI) - </b>
                    Ofrece una solución de performance integral que genera eficiencia en la generación de talento en el fútbol, 
                    proporcionando conclusiones contrastadas con el <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> Mapa de Oportunidad </b>
                    para la toma de decisiones deportivas y de mercado permitiendo mayor monetización de talento y ROI.
                </p>
                <p className="hwdiText">
                <b className="hwdiNumber">2.</b>
                    <b style={{color:"#00ffff", fontFamily:"var(--font700)"}}> FAN 2.0 – Contenido –   </b>
                    Se permite la monetización a través de suscripciones mensuales, brindando acceso 
                    exclusivo a contenido generado por el club y ofreciendo la oportunidad de colaborar.
                </p>
            </div>
            <div  >
                <img src={HowWeDoItImage} alt="" className='hwdiImg'/>
            </div>

        </div>
    </>
    );
};
export default HowWeDoIt;